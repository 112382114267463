"use client";

import { TagVariant } from "@/lib/types";
import { cn, getUniqueTags } from "@/lib/utils";
import { useEffect, useState } from "react";
import { getSliderTitle } from "./Slider";
import Tag from "@/components/ui/tag";
import Arrow from "@/components/icons/Arrow";
import RichText from "@/components/general/nsc/RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { useSearchParams } from "next/navigation";

const AccordionArchiveSection = ({ items, variant, noTags, data }: any) => {
  let uniqueTags: TagVariant[] = [];

  if (!noTags) {
    items = items.map((item: any) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          tags: item.attributes.tags.map((tag: any) => {
            return tag.name;
          }),
        },
      };
    });

    uniqueTags = getUniqueTags(items);
    if (variant !== "Over ons") {
      uniqueTags = getUniqueTags(items);
    } else {
      uniqueTags = ["Over ons"];
    }
  }

  const [openItem, setOpenItem] = useState(items[0]);

  const searchParams = useSearchParams();
  const item = searchParams.get("item");

  const scrollToItem = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (item && items.find((i: any) => item === i.title.toLowerCase().replaceAll(" ", "-"))) {
      scrollToItem(item);
      setOpenItem(items.find((i: any) => item === i.title.toLowerCase().replaceAll(" ", "-")));
    }
  }, [item]); // TODO: Fix this warning

  return (
    <section className="mb-16 md:mb-24">
      {/* Desktop */}
      <div className="hidden grid-cols-12 gap-x-5 md:grid">
        <div className="col-span-12 mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
          <h2 className="header-3">{data?.title || getSliderTitle(variant)}</h2>
          <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
          {!noTags && (
            <div className="flex gap-2">
              {uniqueTags.map((tag, index) => (
                <Tag key={index} variant={tag} rounded />
              ))}
            </div>
          )}
        </div>
        <div className="col-span-4">
          {items.map((item: any) => (
            <div
              key={item.id}
              className="group flex flex-col gap-5 border-b border-[rgba(23,28,96,0.4)] py-6 md:col-span-6 md:flex-row md:items-center md:gap-5"
            >
              <button
                className="flex max-w-[400px] flex-1 items-start gap-2 text-start font-roboto font-bold text-nsc-dark-blue"
                onClick={() => setOpenItem(item)}
              >
                <span>{item?.attributes?.title || item.title}</span>
              </button>
              <Arrow
                direction={openItem.id === item.id ? "down" : "right"}
                className={cn(
                  "ml-auto transition-transform group-hover:translate-x-1",
                  openItem.id === item.id ? "!translate-x-2 rotate-90" : ""
                )}
              />
            </div>
          ))}
        </div>
        <div className="col-span-8 col-start-6">
          {(openItem.attributes?.shortDescription || openItem.attributes?.description || openItem?.description) && (
            <>
              <h2 className="mb-8 text-balance font-oswald text-[32px] font-bold uppercase leading-[110%] lg:text-[48px]">
                {openItem?.attributes?.title || openItem.title}
              </h2>
              <RichText
                data={
                  openItem?.attributes?.shortDescription || openItem?.attributes?.description || openItem?.description
                }
                className="rich-text"
              />
            </>
          )}
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden">
        <div className="col-span-12 mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
          <h2 className="header-3">{getSliderTitle(variant)}</h2>
          <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
          <div className="flex gap-2">
            {uniqueTags.map((tag) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div>
        </div>

        <Accordion type="single" collapsible className="w-full">
          {items.map((item: any) => (
            <AccordionItem key={item.id} value={item.id}>
              <AccordionTrigger onClick={() => setOpenItem(item)}>
                {item?.attributes?.title || item.title}
              </AccordionTrigger>
              <AccordionContent>
                {(item?.attributes?.description || item?.description || openItem?.attributes?.shortDescription) && (
                  <RichText
                    data={
                      openItem?.attributes?.shortDescription ||
                      openItem?.attributes?.description ||
                      openItem?.description
                    }
                    className="rich-text"
                  />
                )}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default AccordionArchiveSection;
