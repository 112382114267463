"use client";

import RichText from "@/components/general/nsc/RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { getStrapiURL } from "@/lib/api-helpers";
import DynamicForm from "@/components/general/DynamicForm";

const ContactUsSection = ({ data }: any) => {
  const handleSubmit = async (formData: FormData) => {
    const rawFormData = {
      name: formData.get("firstname") + " " + formData.get("lastname"),
      subject: formData.get("subject"),
      email: formData.get("email"),
      message: formData.get("message"),
      type: "contact",
      metadata: {
        pageUrl: window.location.href,
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cookiesEnabled: navigator.cookieEnabled,
      }
    };

    return fetch(`${getStrapiURL(`/api/nsc-submissions`)}`, {
      method: "POST",
      body: JSON.stringify({
        data: rawFormData,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const formFields = [
    {
      id: "firstname",
      label: "Voornaam*",
      type: "text",
      required: true,
      errorMessage: "Voornaam is verplicht in te vullen",
    },
    {
      id: "lastname",
      label: "Achternaam*",
      type: "text",
      required: true,
      errorMessage: "Achternaam is verplicht in te vullen",
    },
    {
      id: "email",
      label: "E-mail*",
      type: "email",
      required: true,
      errorMessage: "E-mail is verplicht in te vullen",
      autoComplete: "email",
    },
    {
      id: "subject",
      label: "Onderwerp*",
      type: "text",
      required: true,
      errorMessage: "Onderwerp is verplicht in te vullen",
    },
    { id: "message", label: "Vraag*", type: "textarea" },
    {
      id: "terms",
      label:
        "Ja, ik ga akkoord met het <a href='/privacy-statement' target='_blank' rel='noopener noreferrer'>privacy statement</a> en de algemene voorwaarden",
      type: "checkbox",
      required: true,
      errorMessage:
        "Om dit formulier in te vullen, moet je akkoord gaan met het privacy statement en de algemene voorwaarden",
    },
  ];

  return (
    <section className="py-16 md:py-32">
      <div className="grid-container">
        <div className="order-first col-span-12 md:hidden">
          {data?.description && (
            <RichText data={data.description} className="rich-text mb-0 border-[rgba(23,28,96,0.4)] pb-8" />
          )}
        </div>
        <div className="col-span-12 mt-16 md:col-span-6 md:mt-0 lg:col-span-5">
          <h2 className="header-3 mb-8 hidden !text-balance md:block md:text-[42px]">Contact</h2>
          {data?.description && (
            <RichText
              data={data.description}
              className="rich-text mb-0 hidden border-b border-[rgba(23,28,96,0.4)] pb-8 md:block"
            />
          )}
          <Accordion type="single" collapsible className="w-full">
            {data.faq.items.map((item: any) => (
              <AccordionItem key={item.id} value={item.id}>
                <AccordionTrigger className="header-3 !border-none pb-8 data-[state=open]:pb-0">
                  {item.title}
                </AccordionTrigger>
                <AccordionContent>
                  {item?.description && <RichText data={item.description} className="rich-text" />}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="relative left-[-10px] order-first col-span-12 h-fit w-screen bg-nsc-dark-blue px-[10px] py-16 md:left-0 md:order-last md:col-span-6 md:col-start-7 md:w-full md:rounded md:p-10 lg:p-16">
          <h2 className="header-3 mb-8 text-white md:text-[42px]">{data?.formTitle}</h2>
          <DynamicForm
            fields={formFields}
            onSubmit={handleSubmit}
            buttonLabel="Contact opnemen"
            sendMessage="Bedankt voor het contact opnemen. We nemen zo snel mogelijk contact met u op."
          />
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
