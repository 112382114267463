"use client";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Tag from "@/components/ui/tag";
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { cn } from "@/lib/utils";
import { formatDate } from "@/lib/api-helpers";

const TimelineSection = ({ data }: any) => {
  const tags = data.tags.map((tag: any) => tag.name);

  const [api, setApi] = useState<CarouselApi>();

  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section className="w-full">
      {data.title && (
        <div className="mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
          <h2 className="header-3">{data.title}</h2>
          <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
          <div className="flex gap-2">
            {tags.map((tag: any) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div>
        </div>
      )}

      <Carousel
        opts={{
          align: "start",
        }}
        className={cn("mx-auto mb-16 w-screen sm:w-full md:w-[80%]")}
        setApi={setApi}
      >
        <CarouselContent className="">
          {data.items.map((item: any, index: number) => (
            <CarouselItem key={item.id} className="basis-full pl-0 sm:basis-1/2 lg:basis-1/3">
              <TimelineItem data={item} index={item.id} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="flex w-[calc(100vw-20px)] items-center justify-between md:absolute md:top-[calc(50%-32px)] md:w-full md:-translate-y-1/2">
          <CarouselPrevious className="md:-translate-x-20" />
          <div className="flex justify-center gap-2 md:hidden">
            {count > 1 &&
              Array.from({ length: count }).map((_, index) => (
                <motion.button
                  aria-label={`Scroll naar slide ${index + 1}`}
                  onClick={() => api?.scrollTo(index)}
                  animate={{ opacity: current === index + 1 ? 1 : 0.5 }}
                  key={index}
                  className="h-4 w-4 rounded-full bg-nsc-dark-blue p-0"
                />
              ))}
          </div>
          <CarouselNext className="md:translate-x-20" />
        </div>
      </Carousel>
    </section>
  );
};

const TimelineItem = ({ data, index }: any) => {
  const date = formatDate(data.date, true);
  const yearDate = date.split(" ")[2];
  const monthDate = date.split(" ")[1];
  return (
    <article
      className={cn(
        "lg:gap10 relative flex h-full flex-col items-center justify-between gap-10 sm:gap-20 md:gap-40 lg:gap-24",
        index % 2 === 0 && "flex-col-reverse"
      )}
    >
      <div className={cn("relative mb-0 flex h-[150px]", index % 2 === 0 ? "items-end" : "items-start")}>
        <h3 className="header-2">
          {yearDate} <span className="text-[22px]">{monthDate}</span>
        </h3>
      </div>
      <div className="absolute top-1/2 h-[1px] w-full -translate-y-1/2 transform bg-nsc-dark-blue">
        <div
          className={cn(
            "absolute left-1/2 top-0 h-[90px] w-[1px] -translate-x-1/2 bg-nsc-dark-blue sm:h-[100px] md:h-[150px] lg:h-[120px] xl:h-[100px]",
            index % 2 !== 0 && "bottom-0 top-[unset]"
          )}
        />
        <div className="absolute left-1/2 top-1/2 h-[18px] w-[18px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-nsc-dark-blue" />
      </div>
      <div className={cn("relative w-[90%] truncate")}>
        <p className="h-full text-balance text-center font-calluna text-lg font-light leading-[30px]">
          {data.description}
        </p>
      </div>
    </article>
  );
};

export default TimelineSection;
